import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store, { persistor } from './redux/store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  AUTH,
  CHALLENGE,
  CHALLENGE_PARAMS,
  EXTERNAL_FEEDBACK,
  HOME,
  LOADING_SCREEN,
  OFFBOARDING_QUEST,
  ONBOARDING,
  PROFILE,
  RELATIONSHIPS,
  SCHEDULE_COMMIT,
  DAILY_REMINDER_TIME,
  SETTINGS,
  SHOWCASE,
  SHOWCASE_PARAMS,
  OVERVIEW,
  GENERIC_ERROR_SCREEN,
} from 'routes';
import theme from 'utils/theme';
import DateFnsUtils from '@date-io/date-fns';

import Auth from 'modules/auth';
import Onboarding from 'modules/onboarding';
import LoadingScreen from 'modules/loading-screen';
import Home from 'modules/home';
import PrivateRoute from 'modules/auth/private-route';
import Challenge from 'modules/challenge';
import Profile from 'modules/profile';
import Showcase from 'modules/showcase';
import Relationships from 'modules/relationships';
import Settings from 'modules/settings';
import ScheduleCommit from 'modules/loading-screen/schedule-commit';
import ExternalFeedback from 'modules/external-feedback';
import QuestOffboarding from 'modules/onboarding/quest-offboarding';
import DailyReminderTime from 'modules/loading-screen/daily-reminder-time';
import Overview from 'modules/overview';
import Modal from 'modules/modal';
import GenericErrorScreen from 'modules/generic-error-screen';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer limit={1} hideProgressBar />
            <Modal />
            <Switch>
              <Route path={AUTH} component={Auth} />
              <PrivateRoute path={ONBOARDING} component={Onboarding} />
              <PrivateRoute
                path={OFFBOARDING_QUEST}
                component={QuestOffboarding}
              />
              <PrivateRoute path={LOADING_SCREEN} component={LoadingScreen} />
              <PrivateRoute
                path={GENERIC_ERROR_SCREEN}
                component={GenericErrorScreen}
              />
              <PrivateRoute path={SCHEDULE_COMMIT} component={ScheduleCommit} />
              <PrivateRoute
                path={DAILY_REMINDER_TIME}
                component={DailyReminderTime}
              />
              <PrivateRoute path={HOME} component={Home} />
              <PrivateRoute
                path={CHALLENGE + CHALLENGE_PARAMS}
                component={Challenge}
              />
              <PrivateRoute path={RELATIONSHIPS} component={Relationships} />
              <PrivateRoute path={SETTINGS} component={Settings} />
              <PrivateRoute path={PROFILE} component={Profile} />
              <Route path={EXTERNAL_FEEDBACK} component={ExternalFeedback} />
              <Route path={SHOWCASE + SHOWCASE_PARAMS} component={Showcase} />
              <Route path={OVERVIEW} component={Overview} />
              <Redirect to={HOME} />
            </Switch>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
