import React, { useMemo, useRef, useEffect } from 'react';
import {
  OnboardingButton,
  OnboardingItemContainer,
  OnboardingItemContent,
  OnboardingItemDescription,
  OnboardingItemImage,
  OnboardingItemTitle,
  OnboardingItemTitleAndDescription,
  OnboardingItemVideo,
  RowOnboarding,
} from 'modules/onboarding/components';
import useContentfulRenderer from 'hooks/useContentfulRenderer';
import { Onboarding } from 'models/Onboarding';
import localizedStrings from 'localization';
import theme from 'utils/theme';
import {
  // addContactActiveCampaign, createContactActiveCampaign, getContactActiveCampaignByEmail,
  notifyQuestOffboardingSeen
} from '../actions';
import { useDispatch, useSelector } from 'react-redux';
// import { selectEmail, selectFirstName, selectLastName } from 'modules/auth/selectors';
import { selectContactActiveCampaignId, selectIsNewContactActiveCampaign } from '../selectors';
import { selectActiveQuest } from 'modules/loading-screen/quests/selectors';
import { useHistory } from 'react-router';
import { HOME } from 'routes';
import { CONTACT_OPTIONS } from '../onboarding-constants';
import { DoneButton } from 'models/DoneButton';

type SharedOnboardingProps = {
  item: Onboarding;
  lastItem: boolean;
  activeIndex: number;
  handleGetStarted: () => void;
};

const OnboardingItem = ({
  item,
  lastItem,
  activeIndex,
  handleGetStarted,
}: SharedOnboardingProps) => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const isAutoPlay = item.number - 1 === activeIndex;
  const isPaused = item.number - 1 !== activeIndex;

  const buttonMain = item.button
  const buttonAction = item?.buttonAction
  const buttonActionAdditional = item?.buttonActionAdditional

  // const email = useSelector(selectEmail)
  // const firstName = useSelector(selectFirstName)
  // const lastName = useSelector(selectLastName)

  const newContactActiveCampaign = useSelector(selectIsNewContactActiveCampaign)
  const contactId = useSelector(selectContactActiveCampaignId)
  const activeQuest = useSelector(selectActiveQuest);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isAutoPlay && vidRef.current) {
      vidRef.current.play();
    } else if (isPaused && vidRef.current) {
      vidRef.current.pause();
    }
  }, [isAutoPlay, isPaused, vidRef]);

  const description = useContentfulRenderer(item.description, {
    darkMode: true,
  });

  const image = useMemo(
    () => (item.imageWeb ? `https:${item.imageWeb.fields.file.url}` : ''),
    [item],
  );

  const video = useMemo(
    () => (item.videoWeb ? `https:${item.videoWeb.fields.file.url}` : ''),
    [item],
  );

  const handleButtonAction = (btnItem: DoneButton) => {
    const contactExists = newContactActiveCampaign === CONTACT_OPTIONS.CONTACT_EXISTS;
    const buttonActions = btnItem?.action;

    if (contactExists && contactId && buttonActions && Boolean(buttonActions?.length)) {
      buttonActions.forEach(tag => {
        const tagNumber = parseInt(tag.split('-')[1]);
        if (!isNaN(tagNumber)) {
          // Service with Payment Required. Active Campaign Disabled
          // dispatch(addContactActiveCampaign({ contact: contactId, tag: tagNumber.toString() }))
        }
      });
    }
    if (activeQuest) {
      dispatch(notifyQuestOffboardingSeen({ questId: activeQuest.id }));
    }
    history.push(HOME);
  }

  const handleButtonLast = () => {
    if (buttonMain?.action) {
      handleButtonAction(buttonMain)
    } else {
      handleGetStarted()
    }
  }

  // Service with Payment Required. Active Campaign Disabled
  // useEffect(() => {
  //   if (email) {
  //     dispatch(getContactActiveCampaignByEmail(encodeURIComponent(email)))
  //   }
  //   return () => { }
  // }, [email, dispatch])

  // Service with Payment Required. Active Campaign Disabled
  // useEffect(() => {
  //   const canContactActiveCampaign = newContactActiveCampaign === CONTACT_OPTIONS.IS_NEW
  //   if (canContactActiveCampaign && email && firstName && lastName) {
  //     dispatch(
  //       createContactActiveCampaign({
  //         email,
  //         firstName,
  //         lastName,
  //         phone: '-',
  //       }),
  //     );
  //   }
  //   return () => { }
  // }, [email, firstName, lastName, newContactActiveCampaign, dispatch])

  return (
    <OnboardingItemContainer>
      {video ? (
        <OnboardingItemVideo
          ref={vidRef}
          src={video}
          autoPlay={isAutoPlay}
          controls
        />
      ) : (
        <OnboardingItemImage backgroundImage={image} />
      )}
      <OnboardingItemContent>
        <OnboardingItemTitleAndDescription>
          <OnboardingItemTitle>{item.title}</OnboardingItemTitle>
          <OnboardingItemDescription>{description}</OnboardingItemDescription>
        </OnboardingItemTitleAndDescription>

        <RowOnboarding>

          {buttonAction ?
            <OnboardingButton
              onClick={() => handleButtonAction(buttonAction)}
              backgroundColor={buttonAction?.color || theme.colors.green[90]}>
              {buttonAction?.text || localizedStrings.doNextChallenge}
            </OnboardingButton> : null}

          {buttonActionAdditional ?
            <OnboardingButton
              onClick={() => handleButtonAction(buttonActionAdditional)}
              backgroundColor={buttonActionAdditional?.color || theme.colors.green[90]}>
              {buttonActionAdditional?.text || localizedStrings.done}
            </OnboardingButton> : null}

          {lastItem && (
            <OnboardingButton
              onClick={handleButtonLast}
              backgroundColor={buttonMain?.color}>
              {buttonMain?.text || localizedStrings.getStarted}
            </OnboardingButton>
          )}

        </RowOnboarding>

      </OnboardingItemContent>
    </OnboardingItemContainer>
  );
};

export default OnboardingItem;
