import styled from "@emotion/styled";

export const ArtifactTitle = styled.div`
  color: ${(props) => props?.theme?.colors.blue2[100]};
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1rem;
`;
export const ArtifactDescription = styled.div`
  color: ${(props) => props?.theme?.colors.textCardText.balck};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1rem;  
`;
export const ArtifactModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ArtifactInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
