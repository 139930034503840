import styled from '@emotion/styled';
import { Field, Form } from 'formik';

type InsertTableModalProps = {
  visible: boolean;
};

export const InsertTableModal = styled.div<InsertTableModalProps>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  border: 1px solid grey;
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 8px;
  position: absolute;
  z-index: 1;
  width: 150px;
`;

export const FormContent = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const FormField = styled(Field)`
  background-color: ${(props) => props?.theme?.colors.white[100]};
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 8px;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
`;

export const InsertTableButton = styled.button`
  width: 100% !important;
`;

export const ToolbarButton = styled.button`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

export const ModalText = styled.div`
  color: white;
  font-size: ${(props) => props.theme?.typography.large24};
  font-weight: ${(props) => props.theme?.typography.semiBold};
`;

type RichtTextContainerProps = {
  disabled?: boolean;
};

export const RichtTextContainer = styled.div<RichtTextContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: ${(props) => (props.disabled ? props?.theme?.colors.grey[70] : props?.theme?.colors.white[100])};
  width: 100%;
`;
