import * as yup from 'yup';

import localizedStrings from 'localization';
import { isEmailUnique } from 'modules/auth/services';

const passwordField = yup
  .string()
  .required(localizedStrings.passwordRequiredMessage)
  .min(8, localizedStrings.passwordMinLengthMessage);

const emailField = yup
  .string()
  .email(localizedStrings.emailValidMessage)
  .required(localizedStrings.emailRequiredMessage);

export const loginSchema = yup.object().shape({
  email: emailField,
  password: yup.string().required(localizedStrings.passwordRequiredMessage),
});

export const registerSchema = yup.object().shape({
  displayName: yup
    .string()
    .required(localizedStrings.nameRequiredMessage)
    .max(50, localizedStrings.nameMaxLength),
  email: emailField.test(
    'checkUniqueEmail',
    'Email is already in use by another account',
    function (value: string) {
      if (!value) {
        return Promise.resolve(false);
      }

      return new Promise((resolve) => {
        isEmailUnique({ email: value })
          ?.then(resolve)
          .catch(() => resolve(false));
      });
    } as any,
  ),
  password: passwordField,
  code: yup
    .string()
    .required(localizedStrings.enrollmentCodeRequiredMessage)
    .matches(/^[0-9]*$/g, localizedStrings.enrollmentCodeMatchesMessage)
    .min(5, localizedStrings.enrollmentCodeMinMessage)
    .max(6, localizedStrings.enrollmentCodeMaxMessage)
});

export const forgotPasswordSchema = yup.object().shape({
  email: emailField,
});

export const emailVerificationSchema = yup.object().shape({
  verificationCode: yup
    .string()
    .required(localizedStrings.verificationCodeRequiredMessage)
    .matches(/^[0-9]*$/g, localizedStrings.verificationCodeMatchesMessage)
    .min(5, localizedStrings.verificationCodeMinMessage)
    .max(5, localizedStrings.verificationCodeMaxMessage),
});

export const resetPasswordSchema = yup.object().shape({
  verificationCode: yup
    .string()
    .required(localizedStrings.verificationCodeRequiredMessage)
    .matches(/^[0-9]*$/g, localizedStrings.verificationCodeMatchesMessage)
    .min(5, localizedStrings.verificationCodeMinMessage)
    .max(5, localizedStrings.verificationCodeMaxMessage),
  password: passwordField,
  repeatPassword: passwordField.oneOf(
    [yup.ref('password')],
    localizedStrings.passwordDifferentMessage,
  ),
});
