import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppletByFilterQuest } from 'hooks/useAppletByFilterQuest';
import { selectAvailableApplets } from 'modules/applets/selectors';
import { QuestSectionApplets } from '../components';
import { QuestApplet } from 'models/QuestApplet';
import AppletPreview from '../portfolio/quest-applets/AppletPreview';
import { ActivitySeparator } from 'modules/avatar-options-menu/components';
import AppletPreviewModal from '../portfolio/quest-applets/AppletPreviewModal';
import { ModalState } from '../portfolio/quest-applets';

const previewHeight = '100px';
const previewWidth = '105px';

const ArtifactsGalleryFeatured = () => {
  const [modalState, setModalState] = useState<ModalState>({});

  const questPortfolios = useSelector(selectAvailableApplets);
  const { appletsFeatured } = useAppletByFilterQuest(questPortfolios);
  const openAppletPreviewModal = (applet: QuestApplet) => {
    setModalState({ appletPreviewModalOpen: true, applet });
  };

  const closeModal = () => {
    setModalState({});
  };

  const renderItem = useCallback((applet: QuestApplet) => {
    return (
      <AppletPreview
        key={applet.id}
        applet={applet}
        onAppletClick={() => openAppletPreviewModal(applet)}
        showTitle={true}
        height={previewHeight}
        width={previewWidth}
      />
    );
  }, []);

  return (
    <>
      <AppletPreviewModal
        open={modalState.appletPreviewModalOpen ?? false}
        applet={modalState.applet!}
        closeModal={closeModal}
      />
      <ActivitySeparator />
      <QuestSectionApplets>
        {appletsFeatured.map((applet) => renderItem(applet))}
      </QuestSectionApplets>
    </>
  );
};

export default ArtifactsGalleryFeatured;
