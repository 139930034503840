import { UploadFile } from 'components/applets/app-upload';
import { AppletType, MadlibAnswer } from 'models/Applet';
import { IAppletFields } from 'models/contentful';
import { QuestPortfolio } from 'models/QuestPortfolio';

export interface QuestAppletsState {
  list: QuestPortfolio[];
  selectedIdx?: number;
  appletsActiveQuestFilled?: boolean;
  appletsRestQuestFilled?: boolean;
}

export interface UploadGenericFileAppletPayload {
  file: UploadFile;
  questId: string;
  appletId: string;
}

export interface SubmitGenericApplet {
  questId: string;
  appletId: string;
  type: Extract<AppletType, 'generic-rich-text' | 'generic-upload-file'>;
  fileUrl?: string;
  fileType?: string;
  richText?: string;
}

export interface UploadGenericRichTextPayload {
  questId: string;
  appletId: string;
  richText: string;
  type: Extract<AppletType, 'generic-rich-text'>;
}

export interface ShareEmailPayload {
  receiver: string;
  subject: string;
  message: string;
}

export interface ShareAppletEmailPayload
  extends ShareEmailPayload,
  ShareStepAppletInformation {}

export type ShareAppletModalInformation = Omit<
  ShareAppletEmailPayload,
  'receiver' | 'subject' | 'message'
>;

export interface ShareStepAppletInformation {
  appletContentfulId: string;
  type: IAppletFields['type'];
  richText?: string;
  madlibAnswers?: MadlibAnswer[];
  file?: UploadFile;
}

export interface ShareStepEmailPayload extends ShareEmailPayload {
  stepId: string;
  applets?: ShareStepAppletInformation[];
}

export type ShareStepModalInformation = Omit<
  ShareStepEmailPayload,
  'receiver' | 'subject' | 'message'
>;

export enum APPLETS_GET_CURRENT_CONTENT {
  YES = 'yes',
  NO = 'no'
}

export interface UpdateAppletCustomPayload {
  id: string;
  customTitle?: string;
  customSubtitle?: string;
  customDescription?: string;
  richText?: string;
}