import { useSelector } from 'react-redux';
import { selectTags } from 'modules/tags/selectors';
import { QuestPortfolio } from 'models/QuestPortfolio';
import {
  appletToQuestApplet,
  buildQuestAppletsList,
} from 'helpers/AppletHelpers';
import { selectAppletsContentful } from 'modules/loading-screen/contentful/applets/selectors';
import { AppletsTitle } from 'modules/profile/typings';
import { QuestApplet } from 'models/QuestApplet';
import { Tag } from 'modules/tags/typings';

export const useAppletByFilterQuest = (quests: QuestPortfolio[]) => {
  const appletsContentful = useSelector(selectAppletsContentful);
  const tags_filters_order = useSelector(selectTags);

  const allApplets = quests
    .flatMap((q) => q.applets)
    .map((applet) =>
      appletToQuestApplet(applet, appletsContentful[applet.contentfulId]),
    );
  const allAppletsBuilt = buildQuestAppletsList(allApplets)

  const allAppletsQuests: AppletsTitle[] = [
    { title: 'All', applets: allAppletsBuilt },
  ];

  const appletsToRender: QuestApplet[] = buildQuestAppletsList(allAppletsQuests[0].applets);

  const renderItemByQuestTag = (quest: QuestPortfolio) => {
    const appletsBuilt = quest.applets.map((applet) =>
      appletToQuestApplet(applet, appletsContentful[applet.contentfulId]),
    );
    const applets = buildQuestAppletsList(appletsBuilt)
    return { title: quest.title, applets };
  };

  const renderItemByTag = (tag: Tag) => {
    const appletsFilterd = appletsToRender.filter((applet: QuestApplet) => {
      return applet.tags && applet.tags.length > 0
        ? applet.tags[0] === tag.id
        : null;
    });

    return { title: tag.name, applets: appletsFilterd };
  };

  const appletsByChallenge = (challengeApplets: string[]) =>
    appletsToRender.filter(applet => challengeApplets.find((appletChallengeId: string) => appletChallengeId === applet.id))


  const appletsByQuestTag: AppletsTitle[] = quests.map(renderItemByQuestTag);
  const appletsByTagOrdered: AppletsTitle[] = tags_filters_order.map(renderItemByTag);

  const appletsByTagOrderedAll: AppletsTitle[] = [
    ...appletsByTagOrdered,
    ...allAppletsQuests,
  ];

  const appletsFeatured: QuestApplet[] = allApplets.filter(
    (applet) => applet.featured === true,
  );

  return { appletsByQuestTag, appletsByTagOrderedAll, appletsFeatured, appletsByChallenge };
};
