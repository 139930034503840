import { QuizQuestionUpload } from './../modules/applet-upload/typings.d';
import {
  documentToHtmlString,
  Options,
} from '@contentful/rich-text-html-renderer';
import { nanoid } from '@reduxjs/toolkit';
import { Block, Document, Inline, Text } from '@contentful/rich-text-types';

import { Applet, MadlibAnswer, QuizQuestion } from 'models/Applet';
import { AppletContentful } from 'models/AppletContentful';
import { ChallengeApplet } from 'models/ChallengeApplet';
import { IQuizQuestion, IRankOptions } from 'models/contentful';
import { QuestApplet } from 'models/QuestApplet';
import { RankOption } from 'models/RankOption';
import {
  AppletUpload,
  AppletUploadState,
  QuizInformation,
  UpdateQuizQuestionPayload,
} from 'modules/applet-upload/typings';
import { ShareStepAppletInformation } from 'modules/applets/typings';
import {
  RandomSelectionOptionData,
  RandomSelectionUserOption,
} from 'modules/challenge/random-selection-applet-challenge';
import { AppletsState } from 'modules/loading-screen/contentful/applets/typings';
import { FileUploadedInformation } from 'modules/loading-screen/contents/typings';
import { compareIsoStringDates } from './dateHelpers';
import { getHtmlRendererOptions } from 'components/contentful-renders/constants';
import { IMAGE_MIMETYPE } from './FileHelpers';
import LocalizedStrings from 'localization';
import { QuizQuestionAnswerType } from 'models/QuizQuestionAnswerType';
import { RenderRichTextHtmlAppletInformation } from 'models/RenderRichTextHtmlApplet';
import { IQuizQuestionOption, IApplet } from '../models/contentful';

export const sortAppletsByCreatedAt = (
  appletA: ChallengeApplet | QuestApplet,
  appletB: ChallengeApplet | QuestApplet,
) => {
  if (appletA.createdAt && appletB.createdAt) {
    return compareIsoStringDates(
      appletA.createdAt.toString(),
      appletB.createdAt.toString(),
    );
  }
  return 0;
};

export const groupChallengeApplets = (applets: ChallengeApplet[]) => {
  const commonApplets: ChallengeApplet[] = [];
  const groupAppletsMap: Record<string, ChallengeApplet[]> = {};
  const groupAppletsArr: ChallengeApplet[] = [];
  for (const applet of applets) {
    if (applet.groupId) {
      if (!groupAppletsMap[applet.groupId]) {
        groupAppletsMap[applet.groupId] = [];
      }
      groupAppletsMap[applet.groupId].push(applet);
    } else {
      commonApplets.push(applet);
    }
  }
  for (const grpId of Object.keys(groupAppletsMap)) {
    const grpApps = groupAppletsMap[grpId];
    groupAppletsArr.push({
      id: grpApps[0].id,
      appletId: grpApps[0].appletId,
      appletType: 'group-applet',
      title: grpApps[0].title,
      applets: grpApps.sort(
        (appA, appB) => appA.groupOrder! - appB.groupOrder!,
      ),
      createdAt: grpApps[0].createdAt,
      updatedAt: grpApps[0].updatedAt,
    } as ChallengeApplet);
  }
  return [...groupAppletsArr, ...commonApplets].sort(sortAppletsByCreatedAt);
};

export const buildQuestAppletsList = (applets: QuestApplet[]) => {
  const commonApplets: QuestApplet[] = [];
  const groupAppletsMap: Record<string, QuestApplet[]> = {};
  const groupAppletsArr: QuestApplet[] = [];
  applets.forEach((app) => {
    if (app.groupId) {
      if (!groupAppletsMap[app.groupId]) {
        groupAppletsMap[app.groupId] = [];
      }
      groupAppletsMap[app.groupId].push(app);
    } else {
      commonApplets.push(app);
    }
  });
  
  const groupIds = Object.keys(groupAppletsMap);
  groupIds.forEach((grpId) => {
    const grpApps = groupAppletsMap[grpId];
    const appletMedia = grpApps.find(
      (groupApplet) =>
        groupApplet.appletType === 'upload-image' ||
        groupApplet.appletType === 'upload-video' ||
        groupApplet.appletType === 'upload-file',
    );
    const appletFile =
      appletMedia?.fileUrl === 'image/*' || appletMedia?.fileUrl === 'video/*'
        ? appletMedia?.fileUrl
        : appletMedia?.thumbnailUrl;
    groupAppletsArr.push({
      id: grpId,
      contentfulId: grpId,
      appletType: 'group-applet',
      title: grpApps[0].title,
      description: grpApps[0].description,
      thumbnailUrl: appletFile ? appletFile : grpApps[0].thumbnailUrl,
      applets: grpApps,
      createdAt: grpApps[0].createdAt,
      updatedAt: grpApps[0].updatedAt,
      tags: grpApps[0].tags,
    });
  });
  return [...groupAppletsArr, ...commonApplets].sort(sortAppletsByCreatedAt);
};

const getQuestionMadlibAnswer = (
  existingQuestion?: QuizQuestionUpload,
  answerType?: QuizQuestionAnswerType,
  madlibInputContentfulId?: string,
  madlibInputValue?: string,
): Record<string, MadlibAnswer> | undefined => {
  let ret: Record<string, MadlibAnswer> | undefined;
  if (answerType === QuizQuestionAnswerType.MADLIB) {
    if (madlibInputContentfulId) {
      ret = {
        ...(existingQuestion?.madlibAnswers || {}),
        [madlibInputContentfulId || '']: {
          contentfulId: madlibInputContentfulId || '',
          answer: madlibInputValue || '',
        },
      };
    } else {
      ret = {
        ...(existingQuestion?.madlibAnswers || {}),
      };
    }
  }
  return ret;
};

export const updateQuizApplet = (
  state: AppletUploadState,
  action: {
    payload: UpdateQuizQuestionPayload;
    type: string;
  },
) => {
  const {
    payload: {
      applet: {
        id,
        appletId,
        contentfulId,
        quizContentfulId,
        value,
        answer,
        answerType,
        madlibAppletContentfulId,
        madlibInputValue,
        madlibInputContentfulId,
      },
      modified,
    },
  } = action;
  const oldList = state.list;
  const existingApplet = oldList[appletId];
  const existingQuizInformation = existingApplet?.quizInformation;

  const existingQuestionsMap = existingQuizInformation?.questionsMap;
  const existingQuestion = existingQuestionsMap?.[contentfulId];
  const madlibAnswers = getQuestionMadlibAnswer(
    existingQuestion,
    answerType,
    madlibInputContentfulId,
    madlibInputValue,
  );
  const questionsMap = {
    ...existingQuestionsMap,
    [contentfulId]: {
      ...existingQuestion,
      id: contentfulId,
      value,
      answer,
      answerType,
      madlibAppletContentfulId,
      madlibAnswers:
        madlibAppletContentfulId !== existingQuestion?.madlibAppletContentfulId
          ? undefined
          : madlibAnswers,
    },
  };

  const quizInformation = {
    ...existingQuizInformation,
    id: quizContentfulId,
    questionsMap,
  };

  const newApplet: AppletUpload = {
    ...existingApplet,
    id,
    appletId: appletId,
    appletType: 'quiz',
    quizInformation,
  };

  return {
    ...state,
    list: {
      ...oldList,
      [appletId]: newApplet,
    },
    modified: modified ?? state.modified,
  };
};

export const checkHasCompletedSliderQuestions = (
  appletAnswers: Record<string, AppletUpload>,
  quizApplet?: AppletContentful,
) => {
  const quizAppletId = quizApplet?.id;
  if (quizApplet && quizAppletId && appletAnswers[quizAppletId]) {
    const completedQuizApplet = appletAnswers[quizApplet.id];
    if (!completedQuizApplet) {
      return false;
    }
    const sliderQuestions = quizApplet.quiz?.fields.sections
      .flatMap((section) => section.fields.questions)
      .filter((question) => !question.fields.options);
    const sliderQuestionsMap = sliderQuestions?.reduce(
      (acc, question) => ({
        ...acc,
        [question.sys.id]: question,
      }),
      {} as Record<string, IQuizQuestion>,
    );

    const sliderQuestionsCount = sliderQuestions?.length;

    const completedSliderQuestionsCount = Object.values(
      completedQuizApplet.quizInformation?.questionsMap ?? {},
    ).filter((question) => sliderQuestionsMap?.[question.id]).length;
    return sliderQuestionsCount === completedSliderQuestionsCount;
  }
  return true;
};

export const checkHasCompletedOptionQuestions = (
  appletAnswers: Record<string, AppletUpload>,
  appletsContentful: AppletsState['applets'],
  appletId: string,
  isExtraApplet?: boolean,
) => {
  const quizApplet = appletsContentful[appletId];
  const quizAppletId = quizApplet?.id;
  if (quizApplet && quizAppletId && appletAnswers[quizAppletId]) {
    const completedQuizApplet = appletAnswers[quizApplet.id];
    if (!completedQuizApplet) {
      return false;
    }
    if (isExtraApplet && completedQuizApplet.untouched) {
      return true;
    }
    const optionQuestions = quizApplet.quiz?.fields.sections
      .flatMap((section) => section.fields.questions)
      .filter((question) => question.fields.options);
    const optionQuestionsMap = optionQuestions?.reduce(
      (acc, question) => ({
        ...acc,
        [question.sys.id]: question,
      }),
      {} as Record<string, IQuizQuestion>,
    );

    const optionQuestionsCount = optionQuestions?.length;

    const completedOptionQuestionsCount = Object.values(
      completedQuizApplet.quizInformation?.questionsMap ?? {},
    )
      .filter((question) => optionQuestionsMap?.[question.id])
      .filter((question) => {
        const questionAnswer =
          completedQuizApplet.quizInformation?.questionsMap?.[question.id];
        if (questionAnswer) {
          if (
            question.answerType === QuizQuestionAnswerType.MADLIB &&
            questionAnswer?.madlibAppletContentfulId
          ) {
            if (question.madlibAnswers) {
              const madlibAnswerArray = Object.values(question.madlibAnswers);
              return checkMadlibAnswersCompleted(
                madlibAnswerArray,
                appletsContentful[questionAnswer?.madlibAppletContentfulId],
              );
            } else {
              return false;
            }
          } else {
            return questionAnswer?.answer;
          }
        } else {
          return false;
        }
      }).length;
    return optionQuestionsCount === completedOptionQuestionsCount;
  }
  return true;
};

export const checkHasCompletedDecisionTree = (
  appletAnswers: Record<string, AppletUpload>,
  appletId: string,
  applets?: Record<string, AppletContentful>,
  isExtraApplet?: boolean,
) => {
  const decisionTreeApplet = applets?.[appletId];

  if (!decisionTreeApplet) {
    return false;
  }

  const completedDecisionTreeApplet = appletAnswers[appletId];
  if (!completedDecisionTreeApplet) {
    return false;
  }

  if (isExtraApplet && completedDecisionTreeApplet.untouched) {
    return true;
  }

  const filesToUploadCount =
    decisionTreeApplet.decisionTreeBranches?.length ?? 0;
  const filesModified = Object.values(completedDecisionTreeApplet.files || {});
  if (filesModified.length < filesToUploadCount) {
    return false;
  }

  return filesModified.every((file) => file.file && file.description);
};

export const challengeAppletToApplet = (challengeApplet: ChallengeApplet) => {
  const {
    id,
    appletId,
    appletType,
    fileUrl,
    fileType,
    richText,
    text,
    quizQuestions,
    files,
    options,
    createdAt,
    updatedAt,
  } = challengeApplet;
  return {
    id,
    contentfulId: appletId,
    type: appletType,
    fileUrl,
    fileType,
    richText,
    text,
    quizQuestions,
    files,
    options,
    createdAt,
    updatedAt,
  } as Applet;
};

export const appletToChallengeApplet = (
  applet: Applet,
  appletContentful: AppletContentful,
) => {
  const {
    id,
    contentfulId,
    fileUrl,
    fileType,
    richText,
    text,
    quizQuestions,
    files,
    options,
    madlibAnswers,
    createdAt,
    updatedAt,
  } = applet;
  const {
    title,
    type,
    supportedTypes,
    groupId,
    groupOrder,
    uploadPrompt,
    quiz,
    decisionTreeBranches,
    options: optionsContentful,
    madlibSentence,
  } = appletContentful;
  return {
    id,
    appletId: contentfulId,
    appletType: type,
    supportedTypes,
    groupId,
    groupOrder,
    title,
    fileUrl,
    fileType,
    richText,
    text,
    uploadPrompt,
    quiz,
    quizQuestions,
    files,
    decisionTreeBranches,
    options,
    optionsContentful,
    madlibAnswers,
    madlibSentence,
    createdAt,
    updatedAt,
  } as ChallengeApplet;
};

export const appletToQuestApplet = (
  applet: Applet,
  appletContentful: AppletContentful,
) => {
  const {
    id,
    fileUrl,
    fileType,
    richText,
    text,
    quizQuestions,
    files,
    options,
    madlibAnswers,
    createdAt,
    updatedAt,
    tags,
    themes,
    priority,
    audience,
    customTitle,
    customSubtitle,
    customDescription,
  } = applet;
  const {
    id: contentfulId,
    title,
    description,
    thumbnailUrl,
    type,
    groupId,
    groupOrder,
    uploadPrompt,
    quiz,
    options: optionsContentful,
    madlibSentence,
    featured,
  } = appletContentful;
  return {
    id,
    contentfulId,
    appletType: type,
    description,
    thumbnailUrl,
    groupId,
    groupOrder,
    uploadPrompt,
    title,
    fileUrl,
    fileType,
    richText,
    text,
    quiz,
    quizQuestions,
    files,
    options,
    optionsContentful,
    madlibSentence,
    madlibAnswers,
    createdAt,
    updatedAt,
    tags,
    featured,
    themes,
    priority,
    audience,
    customTitle,
    customSubtitle,
    customDescription,
  } as QuestApplet;
};

export const checkHasCompletedMadlib = (
  appletAnswers: Record<string, AppletUpload>,
  appletContentful: AppletContentful,
  appletId: string,
  isExtraApplet?: boolean,
): boolean => {
  const madlibApplet = appletAnswers[appletId];

  if (madlibApplet) {
    if (isExtraApplet && madlibApplet.untouched) {
      return true;
    }
    const answers = madlibApplet.madlibAnswers
      ? Object.values(madlibApplet.madlibAnswers)
      : [];
    return checkMadlibAnswersCompleted(answers, appletContentful);
  }

  return false;
};

const checkMadlibAnswersCompleted = (
  madlibAnswers: MadlibAnswer[],
  appletContentful: AppletContentful,
) => {
  let returnValue = false;
  if (madlibAnswers.length > 0) {
    const arrMadlibInputs = retrieveContentMadlibInputs(
      appletContentful?.madlibSentence?.content,
    );
    const givenAnswersNumber = madlibAnswers.filter(
      (ans) => ans?.answer,
    ).length;
    returnValue = givenAnswersNumber === arrMadlibInputs.length;
  }
  return returnValue;
};

export const retrieveContentMadlibInputs = (
  appletContent?: (Block | Inline | Text)[],
): (Block | Inline | Text)[] => {
  const applets: (Block | Inline | Text)[] = [];
  appletContent?.forEach((content) => {
    if (
      content.nodeType === 'embedded-entry-inline' &&
      content.data.target.sys.contentType.sys.id === 'madlibInput'
    ) {
      applets.push(content);
    } else if (content.nodeType !== 'text' && content.content) {
      const nestedApplets = retrieveContentMadlibInputs(content.content);
      applets.push(...nestedApplets);
    }
  });
  return applets;
};

export const questAppletToChallengeApplet = (
  applet: QuestApplet,
): ChallengeApplet => {
  const {
    id,
    contentfulId,
    appletType: questAppletType,
    groupId,
    groupOrder,
    title,
    uploadPrompt,
    fileUrl,
    fileType,
    richText,
    text,
    quiz,
    quizQuestions,
    files,
    madlibAnswers,
    madlibSentence,
    applets,
    createdAt,
    updatedAt,
  } = applet;

  const appletType = (() => {
    switch (questAppletType) {
      case 'upload-file':
      case 'upload-image':
      case 'upload-video':
      case 'rich-text-entry':
      case 'inline-text':
      case 'quiz':
      case 'decision-tree':
      case 'madlib':
        return questAppletType;
      case 'generic-rich-text':
        return 'rich-text-entry';
      case 'generic-upload-file':
        return 'upload-file';
      case 'add-applet':
        return 'upload-file';
      case 'group-applet':
        return applets?.[0].appletType === 'inline-text'
          ? 'inline-text'
          : 'madlib';
      default:
        return 'upload-file';
    }
  })();

  return {
    id,
    appletId: contentfulId,
    appletType,
    groupId,
    groupOrder,
    title,
    fileUrl,
    fileType,
    richText,
    text,
    uploadPrompt,
    quiz,
    quizQuestions,
    files,
    madlibAnswers,
    madlibSentence,
    createdAt,
    updatedAt,
  };
};

export const appletUploadToChallengeApplet = (
  applet?: AppletUpload,
  appletContentful?: AppletContentful,
): ChallengeApplet | undefined => {
  const {
    id,
    appletId,
    appletType,
    fileUrl,
    fileType,
    richText,
    text,
    options,
    madlibAnswers,
  } = applet || {};
  const {
    supportedTypes,
    groupId,
    groupOrder,
    title,
    uploadPrompt,
    quiz,
    decisionTreeBranches,
    options: optionsContentful,
    madlibSentence,
  } = appletContentful || {};
  const date = new Date();
  const ret = applet
    ? ({
        id: id || '',
        appletId,
        appletType,
        supportedTypes,
        groupId,
        groupOrder,
        title,
        fileUrl,
        fileType,
        richText,
        text,
        uploadPrompt,
        quiz,
        quizQuestions: Object.values(
          applet.quizInformation?.questionsMap || {},
        ).map(
          (question) =>
            ({
              quizQuestionContentfulId: question.id,
              value: question.value,
              answer: question.answer,
            } as QuizQuestion),
        ),
        files: Object.values(applet.files || {}).map((fileInfo) => ({
          contentfulId: fileInfo.fileId,
          description: fileInfo.description,
          fileOrder: fileInfo.fileOrder,
          fileType: fileInfo.file?.type || '',
          fileUrl: fileInfo.file?.uri || '',
        })),
        decisionTreeBranches,
        options,
        optionsContentful,
        madlibAnswers: Object.values(madlibAnswers || {}),
        madlibSentence,
        createdAt: date.toString(),
        updatedAt: date.toString(),
      } as ChallengeApplet)
    : undefined;

  return ret;
};

export const madlibAppletsToAppletUploads = (
  applets: Applet[],
): Record<string, AppletUpload> => {
  const result: Record<string, AppletUpload> = {};

  applets.forEach((applet) => {
    const key = applet.contentfulId;
    result[key] = {
      id: undefined,
      appletType: 'madlib',
      appletId: applet.contentfulId,
      madlibAnswers: applet.madlibAnswers as any,
    };
  });

  return result;
};

export const checkHasCompletedAnswer = (
  appletAnswers: AppletUploadState['list'],
  appletsContentful: AppletsState['applets'],
  applet?: AppletUpload,
  isExtraApplet?: boolean,
) => {
  const { appletId, appletType, untouched } = applet || {};
  switch (appletType) {
    case 'upload-file':
    case 'upload-image':
    case 'upload-video':
      return isExtraApplet
        ? untouched || Boolean(applet?.file)
        : Boolean(applet?.file);
    case 'rich-text-entry':
      if (appletId && appletsContentful[appletId]?.readOnly) {
        return true;
      } else {
        return isExtraApplet
          ? untouched || Boolean(applet?.richText)
          : !untouched && Boolean(applet?.richText);
      }
    case 'inline-text':
      return isExtraApplet
        ? untouched || Boolean(applet?.text)
        : Boolean(applet?.text);
    case 'quiz':
      return checkHasCompletedOptionQuestions(
        appletAnswers,
        appletsContentful,
        appletId || '',
        isExtraApplet,
      );
    case 'decision-tree':
      return checkHasCompletedDecisionTree(
        appletAnswers,
        appletId || '',
        appletsContentful,
        isExtraApplet,
      );
    case 'rank':
      return isExtraApplet
        ? untouched || applet?.options !== undefined
        : applet?.options !== undefined;
    case 'madlib':
      return checkHasCompletedMadlib(
        appletAnswers,
        appletsContentful[appletId || ''],
        appletId || '',
        isExtraApplet,
      );
    case 'random-selection':
      return isExtraApplet
        ? untouched || applet?.text !== undefined
        : applet?.text !== undefined;
    default:
      return false;
  }
};

export const sortOptions = (a: RankOption, b: RankOption) => a.order - b.order;

export const mapOptions =
  (optionsContentful?: IRankOptions[]) => (option: RankOption) => ({
    id: option.id || nanoid(),
    contentfulId: option.contentfulId,
    content: optionsContentful?.find((o) => o.sys.id === option.contentfulId)
      ?.fields.content,
    userInput: option.userInput,
  });

export const mapOptionsContentful = (
  option: IRankOptions,
  optionIndex: number,
) =>
  ({
    id: option.sys.id,
    contentfulId: option.sys.id,
    order: optionIndex,
  } as RankOption);

export const retrieveContentApplets = (stepContent: Block[]): Block[] => {
  const applets: Block[] = [];
  stepContent.forEach((content) => {
    if (
      content.nodeType === 'embedded-entry-block' &&
      content.data.target.sys.contentType.sys.id === 'applet'
    ) {
      applets.push(content);
    } else if (content.content) {
      const nestedApplets = retrieveContentApplets(content.content as Block[]);
      applets.push(...nestedApplets);
    }
  });
  return applets;
};

export const iRankOptionsToRandomSelectionOptionData = (
  option: IRankOptions,
): RandomSelectionOptionData => ({
  id: option.sys.id,
  title: option.fields.title,
  content: option.fields.content,
  optionType: 'contentful-option',
});

export const rankOptionToRandomSelectionUserOption = (
  rankOption: RankOption,
): RandomSelectionUserOption =>
  ({
    id: rankOption.id,
    content: rankOption.userInput,
    title: rankOption.userInput,
    optionType: 'user-option',
  } as RandomSelectionUserOption);

export const randomSelectionUserOptionToRankOption = (
  option: RandomSelectionUserOption,
): RankOption => ({
  order: 0,
  id: option.id,
  userInput: option.content || '',
});

export const quizInformationToQuizQuestion = (
  quizInformation?: QuizInformation,
): QuizQuestion[] | undefined => {
  let ret: QuizQuestion[] | undefined = quizInformation ? [] : undefined;
  if (quizInformation) {
    const { id: quizContentfulId, questionsMap } = quizInformation;
    if (questionsMap) {
      for (const question of Object.values(questionsMap)) {
        const {
          answer,
          id: quizQuestionContentfulId,
          value,
          madlibAppletContentfulId,
          answerType,
          madlibAnswers: questionMadlibAnswersMap,
        } = question;

        const madlibAnswers = questionMadlibAnswersMap
          ? Object.values(questionMadlibAnswersMap)
          : undefined;

        ret?.push({
          answer,
          quizContentfulId,
          quizQuestionContentfulId,
          value,
          madlibAppletContentfulId,
          answerType,
          madlibAnswers,
        });
      }
    }
  }

  return ret;
};

export const checkHasEmptyDecisionBranch = (applet?: AppletUpload) => {
  const { files } = applet || {};
  if (files) {
    return Object.values(files).every(
      ({ file, description }) => file !== null && description !== '',
    );
  } else {
    return false;
  }
};

export const checkHasEmptyMadlibs = (applet?: AppletUpload) => {
  const { madlibAnswers } = applet || {};
  if (madlibAnswers) {
    return Object.values(madlibAnswers || {}).every((ans) => ans.answer);
  } else {
    return false;
  }
};

export const checkShareModalAppletCompleted = (
  applet: ShareStepAppletInformation,
  appletContentful?: AppletContentful,
) => {
  let returnValue = false;
  switch (applet.type) {
    case 'rich-text-entry':
      returnValue = Boolean(applet.richText);
      break;
    case 'madlib':
      returnValue = checkMadlibAnswersCompleted(
        applet.madlibAnswers || [],
        appletContentful!,
      );
      break;
    case 'upload-file':
    case 'upload-image':
    case 'upload-video':
      returnValue = Boolean(applet?.file);
      break;
  }

  return returnValue;
};

export const getDecisionTreeFirstFile = (files: FileUploadedInformation[]) => {
  const orderedFiles = [...files].sort(
    (fileA, fileB) => fileA.fileOrder - fileB.fileOrder,
  );
  const firstFile = orderedFiles[0];

  return firstFile;
};

export const getRichTextAppletDefaultHtml = (
  appletContentful: AppletContentful,
  appletsContentful: Record<string, AppletContentful>,
  appletsUser: Record<string, QuestApplet>,
  firstName?: string,
  lastName?: string,
) => {
  let ret = '';
  if (appletContentful.type === 'rich-text-entry') {
    ret = replaceMergeToken(appletContentful);

    const simpleApplets = getSimpleAppletInformation(
      ret,
      appletsContentful,
      appletsUser,
    );
    const madlibInputs = getMadlibInputInformation(
      ret,
      appletsContentful,
      appletsUser,
    );
    const rankAppletComponents = getRankAppletInformation(
      ret,
      appletsContentful,
      appletsUser,
    );
    const rankAppletComponentsAdditional = getRankAppletAdditionalInformation(
      ret,
      appletsContentful,
      appletsUser,
    );

    const quizAppletComponents = getQuizAppletInformation(
      ret,
      appletsContentful,
      appletsUser,
    );

    const quizMadlibInputs = getQuizMadlibInputInformation(
      ret,
      appletsContentful,
      appletsUser,
    );

    const appletsToReplaceInformation = [
      ...simpleApplets,
      ...madlibInputs,
      ...rankAppletComponents,
      ...rankAppletComponentsAdditional,
      ...quizAppletComponents,
      ...quizMadlibInputs,
    ];

    for (const applet of appletsToReplaceInformation) {
      const { matchedString, stringToBeReplaced } = applet;

      ret = ret.replaceAll(matchedString, stringToBeReplaced);
    }

    ret = replaceUserInformation(ret, firstName, lastName);
  }

  return ret;
};

const replaceMergeToken = (appletContentful: AppletContentful) => {
  const initialDefaultHtml = appletContentful?.defaultHtml || '';

  const topPatternRegexp = /\{%MERGE\+EDIT\}/gm;

  let stringToBeReplaced = '';
  return initialDefaultHtml.replace(topPatternRegexp, stringToBeReplaced);
};

const getSimpleAppletInformation = (
  defaultHtml: string,
  appletsContentful: Record<string, AppletContentful>,
  appletsUser: Record<string, QuestApplet>,
): RenderRichTextHtmlAppletInformation[] => {
  const ret: RenderRichTextHtmlAppletInformation[] = [];
  const simpleAppletRegex = /\{%MERGE_ID=(\w+)\}/g;

  const appletsMatches = defaultHtml.matchAll(simpleAppletRegex);

  for (const applet of appletsMatches) {
    const [matchedString, matchedId] = applet;
    const matchedAppletContentful = appletsContentful[matchedId];
    const matchedAppletUser = appletsUser[matchedId];
    let stringToBeReplaced = '';

    if (!matchedAppletContentful) {
      stringToBeReplaced = LocalizedStrings.invalidApplet;
    } else if (!matchedAppletUser) {
      stringToBeReplaced = LocalizedStrings.incompleteApplet(
        matchedAppletContentful.title,
      );
    } else {
      stringToBeReplaced = getAppletHtml(
        matchedAppletContentful,
        matchedAppletUser,
      );
    }

    ret.push({
      matchedString,
      stringToBeReplaced,
    });
  }

  return ret;
};

// TODO: Fix/rework the logic in getQuizMadlibInputInformation to better fit client's needs. For now the other functions should be enough.
// Joel will check the requirements of quests and let us know in the future.
const getQuizMadlibInputInformation = (
  defaultHtml: string,
  appletsContentful: Record<string, AppletContentful>,
  appletsUser: Record<string, QuestApplet>,
) => {
  const ret: RenderRichTextHtmlAppletInformation[] = [];
  const quizMadlibInputRegex =
    /\{%MERGE_ID=(\w+);%QUESTION_ID=(\w+);%INPUT_ID=(\w+)\}/g;
  const quizAppletMadlibMatches = defaultHtml.matchAll(quizMadlibInputRegex);

  for (const match of quizAppletMadlibMatches) {
    const [
      matchedString,
      quizAppletContentfulId,
      quizQuestionContentfulId,
      madlibInputContentfulId,
    ] = match;

    const quizAppletContentful = appletsContentful[quizAppletContentfulId];
    const quizAppletUser = appletsUser[quizAppletContentfulId];

    let stringToBeReplaced = '';
    if (!quizAppletContentful) {
      stringToBeReplaced = LocalizedStrings.invalidApplet;
    } else if (!quizAppletUser) {
      stringToBeReplaced = LocalizedStrings.incompleteApplet(
        quizAppletContentful.title,
      );
    } else {
      const quizAnswers = quizAppletUser.quizQuestions?.find(
        (ans) => ans.quizQuestionContentfulId === quizQuestionContentfulId,
      );
      const inputAnswer = quizAnswers?.madlibAnswers?.find(
        (ans) => ans.contentfulId === madlibInputContentfulId,
      );
      if (inputAnswer) {
        stringToBeReplaced = inputAnswer.answer;
      } else {
        stringToBeReplaced = LocalizedStrings.invalidMadlibInput;
      }
    }

    ret.push({
      matchedString,
      stringToBeReplaced,
    });
  }

  return ret;
};

const replaceUserInformation = (
  defaultHtml: string,
  firstName?: string,
  lastName?: string,
): string => {
  let ret = defaultHtml;
  const userFirstNamePattern = '{%MERGE_USER_FIRST_NAME}';
  const userLastNamePattern = '{%MERGE_USER_LAST_NAME}';
  const userFirstName = firstName || LocalizedStrings.missingUserInformation;
  const userLastName = lastName || '';

  ret = ret.replaceAll(userFirstNamePattern, userFirstName);
  ret = ret.replaceAll(userLastNamePattern, userLastName);

  return ret;
};

const documentToHtmlStringHelper: (
  document?: Document,
  options?: Partial<Options>,
) => string = (document, options = {}) => {
  if (document) {
    return documentToHtmlString(document, options);
  } else {
    return '';
  }
};

const getQuizAppletInformation = (
  defaultHtml: string,
  appletsContentful: Record<string, AppletContentful>,
  appletsUser: Record<string, QuestApplet>,
): RenderRichTextHtmlAppletInformation[] => {
  const ret: RenderRichTextHtmlAppletInformation[] = [];
  const quizAppletRegex = /\{%MERGE_ID=(\w+);%QUESTION_ID=(\w+)\}/g;
  const quizAppletMatches = defaultHtml.matchAll(quizAppletRegex);

  for (const match of quizAppletMatches) {
    const [matchedString, quizAppletContentfulId, quizQuestionContentfulId] =
      match;
    const quizAppletContentful = appletsContentful[quizAppletContentfulId];
    const quizAppletUser = appletsUser[quizAppletContentfulId];

    let stringToBeReplaced = '';
    if (!quizAppletContentful) {
      stringToBeReplaced = LocalizedStrings.invalidApplet;
    } else if (!quizAppletUser) {
      stringToBeReplaced = LocalizedStrings.incompleteApplet(
        quizAppletContentful.title,
      );
    } else {
      const quizAnswers = quizAppletUser.quizQuestions?.find(
        (ans) => ans.quizQuestionContentfulId === quizQuestionContentfulId,
      );

      if (quizAnswers) {
        const quizQuestions = quizAppletUser.quiz?.fields?.sections
          .flatMap((section) => section.fields.questions)
          .filter((question) => question.fields.options);

        const questionOptionsMap = quizQuestions?.reduce(
          (acc, question) => ({
            ...acc,
            [question.sys.id]: question,
          }),
          {} as Record<string, IQuizQuestion>,
        );

        const foundQuestion =
          questionOptionsMap?.[quizAnswers?.quizQuestionContentfulId];

        if (quizAnswers.answerType === QuizQuestionAnswerType.OPTION) {
          const questionOption = foundQuestion?.fields?.options?.find(
            (item) => {
              const quizOption =
                item.sys.contentType.sys.id === 'quizQuestionOption'
                  ? (item as IQuizQuestionOption)
                  : undefined;

              return (
                quizOption?.fields.result?.toLowerCase() ===
                  quizAnswers.answer?.toLowerCase() ||
                quizOption?.fields.title?.toLowerCase() ===
                  quizAnswers.answer?.toLowerCase()
              );
            },
          );

          const quizQuestionOption =
            questionOption?.sys.contentType.sys.id === 'quizQuestionOption'
              ? (questionOption as IQuizQuestionOption)
              : undefined;

          stringToBeReplaced = documentToHtmlStringHelper(
            quizQuestionOption?.fields?.option,
          );
        } else if (quizAnswers.answerType === QuizQuestionAnswerType.MADLIB) {
          const questionMadlibOption = foundQuestion?.fields?.options?.find(
            (item) => {
              const quizMadlibOption =
                item.sys.contentType.sys.id === 'applet'
                  ? (item as IApplet)
                  : undefined;

              return (
                quizMadlibOption?.sys.id ===
                quizAnswers.madlibAppletContentfulId
              );
            },
          );

          const quizQuestionMadlibOption =
            questionMadlibOption?.sys.contentType.sys.id === 'applet'
              ? (questionMadlibOption as IApplet)
              : undefined;

          const madlibSentence = quizQuestionMadlibOption?.fields.madlibSentence
            ? quizQuestionMadlibOption?.fields.madlibSentence
            : undefined;

          stringToBeReplaced = documentToHtmlStringHelper(
            madlibSentence,
            getHtmlRendererOptions(quizAppletContentful, {
              madlibAnswers: quizAnswers?.madlibAnswers,
            }),
          );
        }
      } else {
        stringToBeReplaced = LocalizedStrings.quizAppletComponent;
      }
    }

    stringToBeReplaced = stringToBeReplaced.replaceAll(/<p>/gm, '');
    stringToBeReplaced = stringToBeReplaced.replaceAll(/<\/p>/gm, '');

    ret.push({
      matchedString,
      stringToBeReplaced,
    });
  }

  return ret;
};

const getMadlibInputInformation = (
  defaultHtml: string,
  appletsContentful: Record<string, AppletContentful>,
  appletsUser: Record<string, QuestApplet>,
): RenderRichTextHtmlAppletInformation[] => {
  const ret: RenderRichTextHtmlAppletInformation[] = [];
  const madlibInputRegex = /\{%MERGE_ID=(\w+);%INPUT_ID=(\w+)\}/g;
  const madlibInputMatches = defaultHtml.matchAll(madlibInputRegex);

  for (const match of madlibInputMatches) {
    const [matchedString, madlibAppletContentfulId, madlibInputContentfulId] =
      match;
    const madlibAppletContentful = appletsContentful[madlibAppletContentfulId];
    const madlibAppletUser = appletsUser[madlibAppletContentfulId];
    let stringToBeReplaced = '';
    if (!madlibAppletContentful) {
      stringToBeReplaced = LocalizedStrings.invalidApplet;
    } else if (!madlibAppletUser) {
      stringToBeReplaced = LocalizedStrings.incompleteApplet(
        madlibAppletContentful.title,
      );
    } else {
      const inputAnswer = madlibAppletUser.madlibAnswers?.find(
        (ans) => ans.contentfulId === madlibInputContentfulId,
      );
      if (inputAnswer) {
        stringToBeReplaced = inputAnswer.answer;
      } else {
        stringToBeReplaced = LocalizedStrings.invalidMadlibInput;
      }
    }
    ret.push({
      matchedString,
      stringToBeReplaced,
    });
  }

  return ret;
};

export const getRankAppletInformation = (
  defaultHtml: string,
  appletsContentful: Record<string, AppletContentful>,
  appletsUser: Record<string, QuestApplet>,
) => {
  const ret: RenderRichTextHtmlAppletInformation[] = [];

  const rankAppletComponentRegex =
    /\{%MERGE_ID=(\w+);%RANK_OPTION_INDEX=(\d+)\}/g;
  const rankAppletComponentMatches = defaultHtml.matchAll(
    rankAppletComponentRegex,
  );

  for (const match of rankAppletComponentMatches) {
    const [matchedString, rankAppletContentfulId, rankAppletAnswerIndexString] =
      match;
    const rankAppletContentful = appletsContentful[rankAppletContentfulId];
    const rankAppletUser = appletsUser[rankAppletContentfulId];
    const rankAppletAnswerIndex = Number(rankAppletAnswerIndexString);
    let stringToBeReplaced = '';

    if (!rankAppletContentful) {
      stringToBeReplaced = LocalizedStrings.invalidApplet;
    } else if (!rankAppletUser) {
      stringToBeReplaced = LocalizedStrings.incompleteApplet(
        rankAppletContentful.title,
      );
    } else {
      const rankAppletComponent = rankAppletUser?.options?.find(
        (opt) => opt.order === rankAppletAnswerIndex - 1,
      );

      if (rankAppletComponent) {
        const { userInput, contentfulId } = rankAppletComponent;
        if (userInput) {
          stringToBeReplaced = userInput;
        } else if (contentfulId) {
          const rankComponentContentful = rankAppletContentful.options?.find(
            (opt) => opt.sys.id === contentfulId,
          );

          if (rankComponentContentful) {
            stringToBeReplaced = documentToHtmlString(
              rankComponentContentful.fields.content!!,
              getHtmlRendererOptions(rankAppletContentful, {
                matchedAppletUser: rankAppletUser,
              }),
            );
          }
        }
      } else {
        stringToBeReplaced = LocalizedStrings.invalidRankAppletComponent;
      }
    }

    ret.push({
      matchedString,
      stringToBeReplaced,
    });
  }

  return ret;
};

export const getRankAppletAdditionalInformation = (
  defaultHtml: string,
  appletsContentful: Record<string, AppletContentful>,
  appletsUser: Record<string, QuestApplet>,
) => {
  const ret: RenderRichTextHtmlAppletInformation[] = [];

  const rankAppletComponentRegex =
    /\{%MERGE_ID=(\w+);%RANK_OPTION_INDEX=(\d+);%ALT_TEXT\}/g;
  const rankAppletComponentMatches = defaultHtml.matchAll(
    rankAppletComponentRegex,
  );

  for (const match of rankAppletComponentMatches) {
    const [matchedString, rankAppletContentfulId, rankAppletAnswerIndexString] =
      match;
    const rankAppletContentful = appletsContentful[rankAppletContentfulId];
    const rankAppletUser = appletsUser[rankAppletContentfulId];
    const rankAppletAnswerIndex = Number(rankAppletAnswerIndexString);
    let stringToBeReplaced = '';

    if (!rankAppletContentful) {
      stringToBeReplaced = LocalizedStrings.invalidApplet;
    } else if (!rankAppletUser) {
      stringToBeReplaced = LocalizedStrings.incompleteApplet(
        rankAppletContentful.title,
      );
    } else {
      const rankAppletComponent = rankAppletUser?.options?.find(
        (opt) => opt.order === rankAppletAnswerIndex - 1,
      );

      if (rankAppletComponent) {
        const { userInput, contentfulId } = rankAppletComponent;
        if (userInput) {
          stringToBeReplaced = userInput;
        } else if (contentfulId) {
          const rankComponentContentful = rankAppletContentful.options?.find(
            (opt) => opt.sys.id === contentfulId,
          );

          if (rankComponentContentful) {
            stringToBeReplaced = documentToHtmlString(
              rankComponentContentful.fields.alternativeTextResult!!,

              getHtmlRendererOptions(rankAppletContentful, {
                matchedAppletUser: rankAppletUser,
              }),
            );
          }
        }
      } else {
        stringToBeReplaced = LocalizedStrings.invalidRankAppletComponent;
      }
    }

    ret.push({
      matchedString,
      stringToBeReplaced: stringToBeReplaced.replace(/p>/g, 'b>'),
    });
  }

  return ret;
};

const getAppletHtml = (
  matchedAppletContentful: AppletContentful,
  matchedAppletUser: QuestApplet,
) => {
  let stringToBeReplaced = '';
  switch (matchedAppletContentful.type) {
    case 'madlib':
      stringToBeReplaced = documentToHtmlStringHelper(
        matchedAppletContentful.madlibSentence,
        getHtmlRendererOptions(matchedAppletContentful, {
          madlibAnswers: matchedAppletUser.madlibAnswers,
        }),
      );
      break;
    case 'rich-text-entry':
      stringToBeReplaced = matchedAppletUser.richText!;
      break;
    case 'upload-file':
    case 'upload-image':
    case 'upload-video':
    case 'generic-upload-file':
      if (matchedAppletUser.fileType === IMAGE_MIMETYPE) {
        stringToBeReplaced = `<img src="${matchedAppletUser.fileUrl}" alt="${matchedAppletContentful.title}" />`;
      } else {
        stringToBeReplaced = `<a data-testid="link-resource" href="${matchedAppletUser.fileUrl}" target="_blank" rel="noreferrer noopener">${matchedAppletContentful.title} </a>`;
      }
      break;
    default:
      stringToBeReplaced = 'This type of applet is not supported yet.';
  }
  return stringToBeReplaced;
};
